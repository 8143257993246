import React from "react";
import { Wrapper, Heading, Content, List } from "../../styled/Box";

function Walletintegration() {
  return (
    <Wrapper id="walletintegration">
      <Heading>2. Wallet Integration</Heading>
      <Content>
        <p>
          Wallet endpoint URL should be provided by operator. The following
          Instant Games methods should be available:
        </p>
        <List>
          <li>❏ /playerInfo</li>
          <li>❏ /bet</li>
          <li>❏ /win</li>
          <li>❏ /cancel</li>
        </List>
      </Content>
    </Wrapper>
  );
}

export default Walletintegration;
