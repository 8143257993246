import React from "react";
import {
  Container,
  Heading,
  Table,
  Url,
  Content,
  Token,
  Section
} from "../../styled/Box";

function Depositfreespins() {
  return (
    <Container id="deposit">
      <Heading>4. Deposit of the Freespins</Heading>
      <Content>
        <div>
          <p>
            For deposit freespin to users, operator have to make request to
            provided url by Playtron:
          </p>
          <Url>
            <p>
              url: {"{"}game-provider-url{"}"}/api/freespins/Deposit
            </p>
          </Url>
          <p>Request example:</p>
        </div>
        <Token>
          <p>{"{"}</p>
          <p>
            "partnerName" : <span>"provider"</span>, <br></br>
            "password" : <span> "*******"</span>, <br></br>
            "accountId" : <span>"1234"</span>, <br></br>
            "amount" : <span> “2”</span>, <br></br>
            "currency" : <span>"whatever"</span>,<br></br>
            ""quantity" : <span>“10”,</span>
            <br></br>
            "validfor" : <span>“7, //days”</span>,<br></br>
            "transactionId" : <span>"unicid"</span>
            <br></br>
          </p>
          <p>{"{"}</p>
          <p></p>
        </Token>
        <Section>
          <Table>
            <thead>
              <tr>
                <th>Property Name</th>
                <th>Description</th>
                <th>Type</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>partnerName</td>
                <td>Operators name</td>
                <td>String (255)</td>
                <td>Unique partners’s identifier</td>
              </tr>
              <tr>
                <td>password</td>
                <td>Access password</td>
                <td>String (255)</td>
                <td>Password will be provided by Playtron</td>
              </tr>
              <tr>
                <td>accountId</td>
                <td>Player’s identifier</td>
                <td>String (255)</td>
                <td>
                  Unique player’s identifier (received from the platform on
                  /playerInfo call)
                </td>
              </tr>
              <tr>
                <td>amount</td>
                <td>Amount of total freespin nominal</td>
                <td>String (255)</td>
                <td>Freespin nominals can be created by operator</td>
              </tr>
              <tr>
                <td>currency</td>
                <td>Currency of transaction</td>
                <td>String (3)</td>
                <td>Currency of transaction</td>
              </tr>
              <tr>
                <td>quantity</td>
                <td>Quantity of nomimals</td>
                <td>String (255)</td>
                <td>Minimum quantity should be 1</td>
              </tr>
              <tr>
                <td>validFor</td>
                <td>Quantity of days/monts</td>
                <td>String (255)</td>
                <td>How long freespins will be valid for user</td>
              </tr>
              <tr>
                <td>transactionId</td>
                <td>Game transaction id</td>
                <td>String (255)</td>
                <td>Unique transaction id on game the provider’s side</td>
              </tr>
            </tbody>
          </Table>

        </Section>
      </Content>
    </Container>
  );
}

export default Depositfreespins;
