import React from "react";
import { Wrapper, Heading, Table, Content, Token, Section } from "../../../styled/Box";

function Bet() {
  return (
    <Wrapper id="bet">
      <Heading>2.2 /bet</Heading>
      <Content>
        <div>
          <p>
            When player details are received and game client rendered then the
            game provider should call bet to commit player bet wallet
            transaction.
          </p>
          <p>Request example:</p>
        </div>
        <Token>
          {" "}
          <p>{"{"}</p>
          <p>
            "sessionToken" : <span>"valid-session-token"</span>, <br></br>
            "accountId" : <span> "Name"</span>, <br></br>
            “productIT” : <span>Playtron-game-ID”</span>, <br></br>
            "trId" : <span>"3949829374"</span>, <br></br>
            "roundId" : <span>1000</span>, <br></br>
            "currency" : <span>"EUR"</span> <br></br>
          </p>
          <p>{"{"}</p>
        </Token>
        <Section>
          <Table>
            <thead>
              <tr>
                <th>Property Name</th>
                <th>Description</th>
                <th>Type</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>sessionToken</td>
                <td>Player’s session token</td>
                <td>String (255)</td>
                <td>Value passed upon game launch as a query parameter</td>
              </tr>
              <tr>
                <td>accountId</td>
                <td>Player’s identifier</td>
                <td>String (255)</td>
                <td>
                  Unique player’s identifier (received from the platform on
                  /playerInfo call)
                </td>
              </tr>
              <tr>
                <td>productId</td>
                <td>Product identifier of the game on the game provider side</td>
                <td>String (255)</td>
                <td>
                  Product id where bet was placed (same as was passed during
                  product launch)
                </td>
              </tr>
              <tr>
                <td>transactionId</td>
                <td>Game transaction id</td>
                <td>String (255)</td>
                <td>Unique transaction id on game the provider’s side</td>
              </tr>
              <tr>
                <td>roundId</td>
                <td>Round id</td>
                <td>String (255)</td>
                <td>Round id on game the provider’s side</td>
              </tr>
              <tr>
                <td>amount</td>
                <td>Transaction amount to process</td>
                <td>Long</td>
                <td>Amount of transaction in currency’s minor units</td>
              </tr>
              <tr>
                <td>currency</td>
                <td>Currency of transaction</td>
                <td>String (3)</td>
                <td>Currency of transaction</td>
              </tr>
            </tbody>
          </Table>
        </Section>

        <Token>
          <p>{"{"}</p>
          <p>
            "status" : <span>“false”</span>, <br></br>
            "balance" : <span>“9000”</span>, <br></br>
            “currency” : <span>“USD”</span> <br></br>
          </p>
          <p>{"{"}</p>
        </Token>
        <Section>
          <Table>
            <thead>
              <tr>
                <th>Property Name</th>
                <th>Description</th>
                <th>Type</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>status</td>
                <td>Status on ongoing transaction</td>
                <td>String (255)</td>
                <td>
                  It is generated according to the relevant situation. For
                  example, if the user does not have enough balance, etc.
                </td>
              </tr>
              <tr>
                <td>balance</td>
                <td>Player’s remaining balance</td>
                <td>Long</td>
                <td>In minor units</td>
              </tr>
              <tr>
                <td>currency</td>
                <td>Currency of transaction</td>
                <td>String (3)</td>
                <td>Currency of transactions</td>
              </tr>
            </tbody>
          </Table>
        </Section>
      </Content>
    </Wrapper>
  );
}

export default Bet;
