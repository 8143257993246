import React from "react";
import { Wrapper, Heading, Table, Content, Token, Section } from "../../../styled/Box";

function PlayerInfo() {
  return (
    <Wrapper id="playerinfo">
      <Heading>2.1 /playerinfo</Heading>
      <Content>
        <div>
          <p>
            This method will be called to get player details (including balance)
            by sessionToken passed to the game client.
          </p>
        </div>
        <Token>
          <p>{"{"}</p>
          <p>
            {" "}
            "sessionToken" : <span>"valid-session-token"</span>
          </p>
          <p>{"{"}</p>
        </Token>
        <Section>
          <Table>
            <thead>
              <tr>
                <th>Property Name</th>
                <th>Description</th>
                <th>Type</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>sessionToken</td>
                <td>Player’s session token</td>
                <td>String (255)</td>
                <td>Value passed upon game launch as a query parameter</td>
              </tr>
            </tbody>
          </Table>
        </Section>

        <Token>
          <p>{"{"}</p>
          <p>
            "country" : <span>"GE"</span>, <br></br>
            "currency" : <span>"USD"</span>, <br></br>
            "balance" : <span>100000</span>, <br></br>
            "userName" : <span>"name"</span>, <br></br>
            "accountId" : <span>"020304"</span> <br></br>
          </p>
          <p>{"{"}</p>
        </Token>
        <Section>
          <Table>
            <caption>Response from the operator should include:</caption>
            <thead>
              <tr>
                <th>Property Name</th>
                <th>Description</th>
                <th>Type</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>accountId</td>
                <td>Unique player identifier</td>
                <td>String (255)</td>
                <td>
                  Unique player’s identifier (received from the platform on
                  /playerInfo call)
                </td>
              </tr>
              <tr>
                <td>balance</td>
                <td>Player’s balance</td>
                <td>Long</td>
                <td>Balance in minor units. Mostly in cents</td>
              </tr>
              <tr>
                <td>currency</td>
                <td>Player’s currency</td>
                <td>String (3)</td>
                <td>ISO-4217-3 format</td>
              </tr>
              <tr>
                <td>country</td>
                <td>Player’s country</td>
                <td>String (2)</td>
                <td>ISO-3166-1 alpha-2 format</td>
              </tr>
              <tr>
                <td>userName</td>
                <td>Player’s display name</td>
                <td>String (255)</td>
                <td>String format</td>
              </tr>
            </tbody>
          </Table>
        </Section>
      </Content>
    </Wrapper>
  );
}

export default PlayerInfo;
