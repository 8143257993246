import styled from "styled-components";

export const Container = styled.div`
  padding: 20px 60px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(203, 212, 225, 0.6);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  p {
    font-size: 18px;
    color: #444444;

    @media screen and (max-width: 992px) {
      font-size: 12px;
    }

    @media screen and (max-width: 992px) {
      font-size: 12px;
      border: 0px;
    }

    @media screen and (min-width: 992px) and (max-width: 1280px) {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 1280px) {
    font-size: 8px;
    padding: 0 30px;
  }

  @media screen and (max-width: 992px) {
    border: none;
    padding: 0 14px;
  }
`;

export const Section = styled.div`
  width: 100%;
  overflow: auto;
`;

export const Wrapper = styled.div`
  padding: 20px 60px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 5px;

  p {
    font-size: 18px;
    color: #444444;

    @media screen and (max-width: 992px) {
      font-size: 12px;
    }

    @media screen and (min-width: 992px) and (max-width: 1280px) {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 1280px) {
    font-size: 8px;
    padding: 0 30px;
  }
`;

export const Heading = styled.h2`
  margin-top: 20px;
  font-weight: 500;
  font-size: 32px;
  line-height: 22px;
  color: #230f6e;
  font-weight: bold;
  margin-bottom: 0;

  @media screen and (max-width: 992px) {
    font-size: 15px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 22px;
    margin-bottom: 5px;
  }
`;

export const Table = styled.table`
  font-size: 20px;
  text-align: left;
  border-spacing: 0;
  margin-bottom: 10px;
  word-wrap: break-word;
  width: auto;
  min-width: 250px;

  th {
    background-color: rgba(203, 212, 225, 0.5);
    padding: 15px 20px;
    border: 0.5px solid #cbd4e1;
    border-opacity: 50%;
    color: #230f6e;

    @media screen and (max-width: 992px) {
      font-size: 13px;
      padding: 10px 5px;
      overflow: visible;
    }
  }

  td {
    width: 25%;
    border: 1px solid #cbd4e1;
    border-opacity: 50%;
    text-align: left;
    vertical-align: top;
    padding: 5px 20px;

    @media screen and (max-width: 992px) {
      font-size: 13px;
      padding: 10px 5px;
      overflow: visible;
    }
  }

  caption {
    text-align: left;
    margin-bottom: 10px;
  }

  p {
    border: 1px solid red;
  }

  @media screen and (max-width: 992px) {
    font-size: 13px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 13px;
  }
`;

export const Url = styled.div`
  padding: 10px 5px 10px 5px;
  background-color: #f6f7f9;
  word-wrap: break-word;

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: black;
  }

  @media screen and (max-width: 992px) {
    font-size: 8px;
  }

  @media screen and (min-width: 992px) and (max-width: 1280px) {
    font-size: 12px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 14px;
  }
`;

export const Content = styled.div`
  margin-left: 30px;
  overflow: hidden;

  @media screen and (max-width: 1280px) {
    font-size: 18px;
  }

  @media screen and (max-width: 992px) {
    font-size: 18px;
    margin-left: 0;
  }
`;

export const Token = styled.div`
  padding: 10px 0 10px 10px;
  background-color: #f6f7f9;
  margin: 20px 0;

  span {
    color: #f2758b;
  }

  p {
    margin: 0;
  }

  @media screen and (max-width: 992px) {
    font-size: 10px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 18px;
  }
`;

export const List = styled.ul`
  list-style-type: none;
  line-height: 30px;
  padding: 0 20px;
`;

export const Requests = styled.div`
  margin-left: 20px;
`;
