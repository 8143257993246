import "./App.css";
import { Menu } from "./components/navigation";
import Main from "./components/main";
import React, { useState } from "react";

function App() {
  const [isOpen, setIsOpen] = useState(window.innerWidth < 992 ? false : true);
  let toggle = () => setIsOpen(!isOpen);
  let closeMenu = () => {
    if (window.innerWidth < 992) {
      setIsOpen(false);
    }
  };

  // const [width, setWidth] = React.useState(window.innerWidth);
  // const breakpoint = 992;

  // React.useEffect(() => {
  //   const handleWindowResize = () => setWidth(window.innerWidth);
  //   window.addEventListener("resize", handleWindowResize);

  //   if (width < 992) {
  //     setIsOpen(false);
  //   }

  //   return () => window.removeEventListener("resize", handleWindowResize);
  // }, []);

  return (
    <div className="App">
      <div className="nav">
        <Menu open={setIsOpen} menu={isOpen} toggle={toggle} />
      </div>
      <div className="main" onClick={closeMenu}>
        <Main menu={isOpen} />
      </div>
    </div>
  );
}

export default App;
