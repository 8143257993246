import React, { useState } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import { BsChevronBarLeft } from "react-icons/bs";
const Menu = (props) => {
  return (
    <Nav style={{ width: props.menu ? "220px" : "15px" }}>
      <div style={{ alignItems: props.menu ? "flex-start" : "center" }}>
        <img
          src={props.menu ? "/images/Logo.png" : "/images/Icon.png"}
          style={{ width: props.menu ? "160px" : "40px" }}
          alt=""
        />
        <Content>
          {props.menu ? (
            <Close onClick={props.toggle}></Close>
          ) : (
            <Icon onClick={props.toggle}></Icon>
          )}
          <h1 style={{ display: props.menu ? "block" : "none" }}>
            Table of contents
          </h1>
          <ol style={{ display: props.menu ? "block" : "none" }}>
            <NavLink
              to="integrationprocess"
              spy={true}
              smooth={true}
              offset={30}
              duration={0}
            >
              <li>Integration process</li>
            </NavLink>

            <NavLink
              to="walletintegration"
              spy={true}
              smooth={true}
              offset={30}
              duration={0}
            >
              <li>Wallet Integration</li>
            </NavLink>
            <OL>
              <NavLink
                to="playerinfo"
                spy={true}
                smooth={true}
                offset={30}
                duration={0}
              >
                <li>Player info</li>
              </NavLink>

              <NavLink
                to="bet"
                spy={true}
                smooth={true}
                offset={30}
                duration={0}
              >
                <li>Bet</li>
              </NavLink>

              <NavLink
                to="win"
                spy={true}
                smooth={true}
                offset={30}
                duration={0}
              >
                <li>Win</li>
              </NavLink>

              <NavLink
                to="cancel"
                spy={true}
                smooth={true}
                offset={30}
                duration={0}
              >
                <li> Cancel</li>
              </NavLink>
            </OL>

            <NavLink
              to="leaderboard"
              spy={true}
              smooth={true}
              offset={30}
              duration={0}
            >
              <li>Leaderboard</li>
            </NavLink>

            <NavLink
              to="deposit"
              spy={true}
              smooth={true}
              offset={30}
              duration={0}
            >
              <li>Deposit of the Freespins</li>
            </NavLink>
          </ol>
        </Content>
      </div>
    </Nav>
  );
};

export { Menu };

const Nav = styled.nav`
  background-color: #001529;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  position: fixed;
  bottom: 0;
  top: 0;
  padding: 20px;

  img {
    margin: 20px 0;
    transition:  all 0.1s;
  }

  h1 {
    font-size: 18px;
    padding-left: 20px;
    padding-right: 40px;
  }

  ol {
    counter-reset: item;
    font-size: 16px;
    line-height: 32px;
    cursor: pointer;
  }

  li {
    counter-increment: item;
    padding-right: 30px;
  }

  ol ol > li {
    display: block;
    opacity: 0.8;
    margin-left: -40px;
    border-left: 1px solid rgba(203, 212, 225, 0.3);
    padding-left: 5px;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 992px) {
    // min-width: 210px;
  }
`;

export const Content = styled.div``;

export const NavLink = styled(Link)`
  &.active {
    color: #1890ff;
  }
`;
const Logo = styled.div`
  border-bottom: 1px solid rgba(203, 212, 225, 0.1);
  img {
    width: 156px;
    margin: 10px 0;
    padding: 0 20px;
  }
`;
export const OL = styled.ol`
  list-style-type: none;
  font-size: 16px;
  line-height: 32px;
  cursor: pointer;
  display: block;
  opacity: 0.8;
  margin-left: 10px;
  border-left: 1px solid rgba(203, 212, 225, 0.3);
  padding-left: 5px;

  li:before {
    content: counters(item, ".") ". ";
  }
`;

const Icon = styled(FaBars)`
  color: white;
  width: 25px;
  height: 50px;
  cursor: pointer;
`;

const Close = styled(BsChevronBarLeft)`
  color: white;
  width: 30px;
  height: 50px;
  display: none;
  cursor: pointer;

  @media screen and (max-width: 992px) {
    display: block;
  }
`;
