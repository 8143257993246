import React from "react";
import Header from "./header/Header";
import IntegrationProcess from "./integrationprocess/IntegrationProcess";
import Walletintegration from "./walletintegration";
import PlayerInfo from "./walletintegration/playerinfo/Playerinfo";
import Bet from "./walletintegration/bet/Bet";
import Win from "./walletintegration/win/Win";
import Cancel from "./walletintegration/cancel/Cancel";
import Leaderboard from "./leaderboard/Leaderboard";
import Depositfreespins from "./depositoffreespins/Depositfreespins";

function Main(props) {
  return (
    <div style={{ marginLeft: props.menu ? "260px" : "40px" }}>
      <Header></Header>
      <IntegrationProcess></IntegrationProcess>
      <Walletintegration></Walletintegration>
      <PlayerInfo></PlayerInfo>
      <Bet></Bet>
      <Win></Win>
      <Cancel></Cancel>
      <Leaderboard></Leaderboard>
      <Depositfreespins></Depositfreespins>
    </div>
  );
}

export default Main;
